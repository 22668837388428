import { FormItem, Input, Select, Switch } from 'formik-antd';
import React, { useCallback } from 'react';
import { Button, DatePicker, Row, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import isBoolean from 'lodash/isBoolean';
import {
  TYPE_BOOLEAN,
  TYPE_INTEGER,
  TYPE_DOUBLE,
  TYPE_DICTIONARY,
  TYPE_ARRAY,
  TYPE_STRING,
  TYPE_IMAGE,
  TYPE_COLOR,
  TYPE_DATE,
  TYPE_SELECT,
} from '../../../Constructors/const/templates';
import isFieldRequired from '../../utils/isFieldRequired';
import useDictionariesOptions from '../../hooks/useDictionariesOptions';
import getFullFieldName from '../../utils/getFullFieldName';
import Colorpicker from '../../../../components/Colorpicker';
import EmptyDictionariesListAlert from '../../../Dictionary/components/EmptyDictionariesListAlert';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import { DATE_FORMAT } from '../../../../const/system';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



const DynamicFormField = ({ field, setFieldValue, values }) => {
  const [ dictionaryOptions, isFetching ] = useDictionariesOptions(field);
  const { onBlur } = useDatepickerHandler();
  const language = useSelector(({ customise: { language } }) => language);

  const fieldName = getFullFieldName(field);

  const handleChange = (event) => {
    setFieldValue(`data.${fieldName}`, {
      value: event.target.value ? event.target.value : isBoolean(event.target.value) ? false : null,
      template_field_id: field.id,
    });
  };

  const searchDictionaries = useCallback((input, option) => (option?.label ?? '')
    .toLowerCase()
    .includes(input.toLowerCase()), []);

  const isRequired = isFieldRequired(field?.validations?.data);

  const label = (
    <Row className="w-full" justify="start" align="middle">
      <div>
        {field[`title_${language}`]}
      </div>
    </Row>
  );

  let element = null;

  switch (field.type) {
    case TYPE_BOOLEAN:
      element = (
        <FormItem
          label={label}
          extra={field[`hint_${language}`]}
          className="ant-form-item-col stretched"
          name={`data.${fieldName}.value`}
          required={isRequired}
        >
          <Switch
            checkedChildren="True"
            unCheckedChildren="False"
            name={`data.${fieldName}.value`}
            onChange={(event) => {
              setFieldValue(`data.${fieldName}`, {
                value: event.target.value,
                template_field_id: field.id,
              });
            }}
          />
        </FormItem>
      );
      break;
    case TYPE_INTEGER:
      element = (
        <FormItem
          label={label}
          extra={field[`hint_${language}`]}
          className="ant-form-item-col stretched"
          name={`data.${fieldName}.value`}
          required={isRequired}
        >
          <Input
            required={isRequired}
            type="number"
            name={`data.${fieldName}.value`}
            onChange={handleChange}
          />
        </FormItem>
      );
      break;
    case TYPE_DOUBLE:
      element = (
        <FormItem
          label={label}
          extra={field[`hint_${language}`]}
          className="ant-form-item-col stretched"
          name={`data.${fieldName}.value`}
          required={isRequired}
        >
          <Input
            required={isRequired}
            type="text"
            name={`data.${fieldName}.value`}
            onChange={handleChange}
          />
        </FormItem>
      );
      break;
    case TYPE_DICTIONARY:
      element = (
        <>
          <EmptyDictionariesListAlert
            isLoading={isFetching}
            options={dictionaryOptions}
          />

          <FormItem
            label={label}
            extra={field[`hint_${language}`]}
            className="ant-form-item-col stretched"
            name={`data.${fieldName}.value`}
            required={isRequired}
          >
            <Select
              showSearch
              filterOption={searchDictionaries}
              allowClear
              name={`data.${fieldName}.value`}
              onChange={(value) => {
                setFieldValue(`data.${fieldName}`, {
                  template_field_id: field.id,
                  value: value || null,
                });
              }}
              options={dictionaryOptions}
              loading={isFetching}
            />
          </FormItem>
        </>
      );
      break;
    case TYPE_ARRAY:
      element = (
        <FormItem
          label={label}
          extra={`${field[`hint_${language}`] || ''} (To add new item press Enter)`}
          className="ant-form-item-col stretched"
          name={`data.${fieldName}.value`}
          required={isRequired}
        >
          <Select
            allowClear
            name={`data.${fieldName}.value`}
            mode="tags"
            className="w-full none"
            onChange={(value) => {
              setFieldValue(`data.${fieldName}`, {
                template_field_id: field.id,
                value: value || null,
              });
            }}
            options={[]}
            dropdownStyle={{ display: 'none' }}
          />
        </FormItem>
      );
      break;
    case TYPE_STRING:
      element = (
        <FormItem
          label={label}
          extra={field[`hint_${language}`]}
          className="ant-form-item-col stretched"
          name={`data.${fieldName}.value`}
          required={isRequired}
        >
          <Input
            required={isRequired}
            type="text"
            name={`data.${fieldName}.value`}
            onChange={handleChange}
          />
        </FormItem>
      );
      break;
    case TYPE_IMAGE:
      element = (
        <FormItem
          label={label}
          extra={field[`hint_${language}`]}
          className="ant-form-item-col stretched"
          name={`data.${fieldName}.value`}
          required={isRequired}
        >
          <Upload
            beforeUpload={() => false}
            listType="picture"
            fileList={values.data[fieldName]?.value || []}
            onChange={({ fileList }) => {
              if (fileList.length > 1) {
                fileList = fileList.slice(-1);
              }
              setFieldValue(`data.${fieldName}`, {
                value: fileList,
                template_field_id: field.id,
              });
            }}
          >
            <div>
              <Button icon={<UploadOutlined className="hp-mr-4" />}>
                <IntlMessages id="ui-general-upload-image" />
              </Button>

            </div>
          </Upload>
        </FormItem>
      );
      break;
    case TYPE_COLOR:
      element = (
        <FormItem
          label={label}
          extra={field[`hint_${language}`]}
          className="ant-form-item-col"
          name={`data.${fieldName}.value`}
          required={isRequired}
        >
          <Colorpicker
            name={`data.${fieldName}.value`}
            defaultValue={values.data[fieldName]?.value || field?.default_value}
            onChange={(value) => {
              setFieldValue(`data.${fieldName}`, {
                value,
                template_field_id: field.id,
              });
            }}
          />
        </FormItem>
      );
      break;
    case TYPE_DATE:
      element = (
        <FormItem
          label={label}
          extra={field[`hint_${language}`]}
          className="ant-form-item-col stretched"
          name={`data.${fieldName}.value`}
          required={isRequired}
        >
          <DatePicker
            name={`data.${fieldName}.value`}
            format={DATE_FORMAT}
            showToday
            value={values.data[fieldName]?.value ? moment(values.data[fieldName]?.value) : null}
            onChange={async (date, dateString) => {
              setFieldValue(`data.${fieldName}`, {
                value: dateString,
                template_field_id: field.id,
              });
            }}
            onBlur={async ({ target }) => {
              await onBlur(`data.${fieldName}.value`, target.value, setFieldValue);
            }}
          />
        </FormItem>
      );
      break;
    case TYPE_SELECT:
      element = (
        <FormItem
          label={label}
          extra={field[`hint_${language}`]}
          className="ant-form-item-col stretched"
          name={`data.${fieldName}.value`}
          required={isRequired}
        >
          <Select
            allowClear
            name={`data.${fieldName}.value`}
            className='w-full'
            onChange={(value) => {
              setFieldValue(`data.${fieldName}`, {
                template_field_id: field.id,
                value: value || null,
              });
            }}
            options={field?.value ? JSON.parse(field.value) : []}
          />
        </FormItem>
      );
      break;
    default:
      break;
  }

  return element;
};

DynamicFormField.propTypes = {
  field: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default DynamicFormField;
