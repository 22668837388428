const features = {
  'features-breadcrumbs-daily': 'Daily',
  'features-breadcrumbs-priority': 'Priority',
  'features-title-notification': 'You can read documentation of this feature here:',
  'features-breadcrumbs-editors-choice': 'Editor\'s choice',
  'features-breadcrumbs-external-promo': 'External promo',
  'features-dictionary-label': 'Dictionary',
  'features-table-featured-column': 'Show in featured',
  'features-delete-successfully': 'Feature data is successfully deleted',
  'features-create-title': 'Create data',
  'features-update-title': 'Edit data',
  'features-filter-title': 'Filter',
  'features-table-delete-btn-confirm-message': 'Are you sure you want to delete this data?',
  'features-form-collapse-title-targets': 'Targets',
  'features-form-image-id-label': 'Image ID',
  'features-form-bee-id-label': 'Bee ID',
  'features-form-show-featured-label': 'Show in featured',
  'features-filter-bee-id-key-help': 'Allowed to specify Bee panel IDs list using "," separator without spaces',
  'features-delete-notification': 'Unable to delete, recent data is already published',
  'features-use-utc': 'Use UTC+0',
  'features-title-info': 'In case when this field is empty it will be filled automatically',
  'features-title-use-automatic-generation-info': 'Title generates automatically',
  'features-use-utc-info': 'The field determines whether the user receives content at server time (on) or their local time (off)',
  'features-show-in-features-info': 'Display feature in app "features section" (Slider at the top of the screen)',
  'features-details-unpublish': 'Unpublish from HC',
  'features-edit-images-to-attach': 'Images ready for use',
  'features-edit-images-to-attach-confirm-message': 'Are you sure you want to use this image? All unsaved changes will be removed.',
  'features-edit-images-to-attach-success': 'Image attached successfully',
  'features-edit-images-to-attach-failed': 'Image attach failed',
  'features-unpublish-failed': 'Failed to unpublish',
  'features-edit-has-changes': 'We inform you about the availability of updates for the HappyColor mobile application. To successfully publish these changes, the user must check the appropriate checkbox located in the bottom right corner of the screen, next to the "Save" button.',
  'features-edit-publish-to-hc': 'Publish changes to HappyColor',
  'features-not-featured-tag': 'Not in featured',
  'features-priority-update-past-disabled': 'Changing the order data for past dates is not permitted',
  'features-priority-update-no-order-changed': 'Order is not changed',
  'features-disabled-template-text-error': 'Current template is inactive. To enable publishing please enable template status first.',
};

export default features;
