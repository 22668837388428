const dictionaries = {
  'dictionaries-breadcrumbs': 'Словари',
  'dictionaries-breadcrumbs-import': 'Импорт',
  'dictionaries-breadcrumbs-import-success': 'Успех',
  'dictionaries-filter-favorite-label': 'Избранные',
  'dictionaries-status-ready': 'Готово',
  'dictionaries-status-draft': 'Черновик',
  'ui-dictionaries-create': 'Создать словарь',
  'ui-dictionaries-label-field': 'Метка',
  'ui-dictionaries-source-field': 'Источник',
  'ui-dictionaries-version-field': 'Версия',
  'ui-dictionaries-tags-field': 'Теги',
  'form-dictionaries-name-placeholder': 'Имя',
  'form-dictionaries-source-placeholder': 'Выберите источник',
  'form-dictionaries-structure-placeholder': 'Выберите версию структуры',
  'form-dictionaries-source-field': 'Источник',
  'form-dictionaries-structure-field': 'Версия структуры',
  'form-dictionaries-label-field': 'Поле',
  'form-dictionaries-validation-field': 'Правила валидации',
  'form-dictionaries-upgrade-field': 'Повысить до последней минорной версии',
  'form-dictionaries-created-from-field': 'Создан с',
  'form-dictionaries-created-to-field': 'Создан по',
  'form-dictionaries-field-field': 'Поле',
  'form-dictionaries-field-value-field': 'Значение поля',
  'form-dictionaries-field-placeholder': 'Выберите поле',
  'dictionaries-create-title': 'Детали словаря',
  'dictionaries-preview-structure-description': 'Описание',
  'dictionaries-preview-structure-fields': 'Поля',
  'dictionaries-preview-structure-languages': 'Языки',
  'dictionaries-update-status-confirm-message': 'Вы точно хотите сделать словарь доступным для публикации?',
  'dictionaries-update-status-confirm-message-danger': 'После подтверждения действия все пустые поля заполнятся значениями из английского языка',
  'dictionaries-new-version-available': 'Доступна новая минорная версия',
  'dictionaries-filter-title': 'Фильтр словарей',
  'translations-edit-button': 'Редактировать словарь',
  'ui-dictionaries-status-ready': 'Отметить как Готовый',
  'dictionaries-missed-translations': 'Переводы еще не добавлены.',
  'dictionaries-translation-preview': 'Предпросмотр переводов словаря',
  'dictionaries-import-title': 'Импорт',
  'dictionaries-export-title': 'Экспорт',
  'dictionaries-export-template': 'Экспорт шаблонов',

  'dictionaries-form-file-label': 'Файл',
  'form-dictionaries-version-placeholder': 'Выберите версию',
  'form-dictionaries-version-label': 'Версия',
  'dictionaries-import-failed': 'Ошибка импорта словарей',
  'dictionaries-imported-list': 'Импортированные словари',
  'dictionaries-structure-label': 'Структура',
  'dictionaries-import-success': 'Словари импортированы успешно',
  'dictionaries-import-card-title': 'Импорт словарей',
  'dictionaries-import-page-failed': 'Упс! Что пошло не так!',
  'dictionaries-table-delete-btn-confirm-message': 'Вы действительно хотите удалить словарь?',
  'dictionaries-deleted-successfully': 'Словарь удален',
};

export default dictionaries;
