import React from 'react';
import { Popconfirm, Row, Tooltip } from 'antd';
import { PiPencil } from 'react-icons/pi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Delete } from 'react-iconly';
import { Eye } from 'iconsax-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import getFormattedDate from '../../../../lib/getFormattedDate';
import { HasRights } from '../../../../components/has-rights/HasRights';
import { Permissions } from '../../../../const/permissions';
import { achievementSettings } from '../../../../const/profileSettings';



export const SEARCH_VALUES = [ 'name' ];
export const SEARCH_FILTER_VALUES = [ 'id', 'code', 'name', 'created_from', 'created_to' ];
export const DEFAULT_SIDEBAR_WIDTH = 600;

export const getAchievementsTableColumns = (handleView, handleEdit, handleDelete) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: '80px',
    sorter: true,
  },
  {
    title: <IntlMessages id="achievements-code-column" />,
    dataIndex: 'code',
    align: 'center',
    width: '90px',
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-name" />,
    dataIndex: 'name',
    align: 'center',
    sorter: true,
  },
  {
    title: <IntlMessages id="achievements-content-column" />,
    align: 'center',
    sorter: false,
    width: '280px',
    render: (value) => {
      return (
        <div className="hp-d-flex">
          {value.images?.data?.slice(0, achievementSettings.previewItems)
            .map((image) => {
              return (
                <LazyLoadImage
                  style={{
                    height: '45px',
                    marginRight: '5px',
                    objectFit: 'cover',
                  }}
                  effect='blur'
                  alt="picture preview"
                  key={image.id}
                  src={image?.preview?.data?.path}
                />
              );
            })}

        </div>
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-created-at" />,
    dataIndex: 'created_at',
    align: 'center',
    sorter: true,
    render: (value) => getFormattedDate(value),
  },
  {
    title: <IntlMessages id="ui-general-action" />,
    key: 'action',
    align: 'center',
    width: '120px',
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          {HasRights([ Permissions.CONTENT.ACHIEVEMENTS.VIEW ], true) && (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
              <div className="hp-text-right">
                <Eye
                  onClick={() => {
                    handleView(item.id);
                  }}
                  size={20}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                />
              </div>
            </Tooltip>
          )}

          {HasRights([ Permissions.CONTENT.ACHIEVEMENTS.EDIT ]) && (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
              <div className="hp-text-right">
                <PiPencil
                  onClick={() => handleEdit(item.id)}
                  size={20}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                />
              </div>
            </Tooltip>
          )}

          {HasRights([ Permissions.CONTENT.ACHIEVEMENTS.DELETE ]) && !item?.images?.data.length && (
            <Popconfirm
              title={<IntlMessages id="achievements-delete-btn-confirm-message" />}
              placement="top"
              onConfirm={() => {
                handleDelete(item.id);
              }}
              okText={<IntlMessages id="ui-general-yes" />}
              cancelText={<IntlMessages id="ui-general-no" />}
              icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
              okButtonProps={{ danger: true }}
            >
              <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                <div className="hp-text-right">
                  <Delete
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                  />
                </div>
              </Tooltip>
            </Popconfirm>
          )}
        </Row>
      );
    },
  },
];
