import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Alert, Card, Col, Input, Modal, Row, Space, Table, Typography } from 'antd';
import { Search } from 'react-iconly';
import { Filter } from 'iconsax-react';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import { RiAddLine, RiCloseFill, RiInformationLine } from 'react-icons/ri';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import appColors from '../../../../const/colors';
import basePagination from '../../../../const/pagination';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPagePopupCreate from '../../../../urls/urlPagePopupCreate';
import urlPagePopupsDetails from '../../../../urls/urlPagePopupsDetails';
import useToggle from '../../../../app/hooks/useToggle';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import useElementSize from '../../../../app/hooks/useElementSize';
import getConfigurations from '../../../../lib/getConfigurations';
import getQueryParams from '../../../../lib/getQueryParams';
import { getPopupsTableColumns, POPUPS_SOURCE } from './PagePopups.const';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../layout/components/action-button';
import Sidebar from '../../../../components/sidebar/Sidebar';
import PreviewDictionaryTranslation from '../../../Dictionary/components/PreviewDictionaryTranslation/PreviewDictionaryTranslation';
import FormPopupsFilter from '../../forms/FormPopupsFilter';
import { PrivateComponent } from '../../../../components/has-rights/HasRights';
import { Permissions } from '../../../../const/permissions';
import { useGetLanguagesQuery } from '../../../Language/api/languagesApiSlice';
import { useDeletePopupMutation, useGetPopupsQuery } from '../../api/popupsApiSlice';



const { Title } = Typography;

const PagePopups = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ elementRef, size ] = useElementSize();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const [ openDictionaryModal, toggleDictionaryModal ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ previewDictionaryId, setPreviewDictionaryId ] = useState({});
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const searchFields = [ 'title' ];
  const searchFilterFields = [ 'id', 'title', 'is_published', 'template', 'published_at', 'released_at', 'released_to' ];
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const [ searchField, setSearchField ] = useState('');

  const source = POPUPS_SOURCE;
  const popupsConfig = getConfigurations(source);

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFields, searchFilterFields, isFilter: true });

  const { data = { data: [], pagination: {} }, isFetching } = useGetPopupsQuery({
    queryParams: searchParams.toString(),
  });

  const { data: languages = { languages: [] } } = useGetLanguagesQuery({ queryParams: 'limit=0' });
  const [ deletePopup, { isLoading: isDeletingPopup } ] = useDeletePopupMutation();

  useEffect(() => {
    setSearchField(search);
  }, []);

  const applyFilter = (values) => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        queryParams[key] = key === 'page' ? 1 : value;
      });

    queryParams.search = Object.entries(values)
      .map(([ field, value ]) => {
        if (searchFilterFields.includes(field)) {
          if ((isArray(value) && !value.length) || value === null) {
            return '';
          }
          return isString(value) ? `${field}:${value.trim()}` : `${field}:${value}`;
        }
        return '';
      })
      .filter((item) => item !== '')
      .join(';');

    setSearchField('');
    setSearchParams(queryParams);
    toggleFilterSidebar();
  };

  const resetFilter = () => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        if (key === 'search') {
          return;
        }

        queryParams[key] = key === 'page' ? 1 : value;
      });

    setSearchField('');
    setSearchParams(queryParams);
    setInitFilterValues({});
  };

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (value !== '') {
          initValues[key] = value;
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);


  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }
  }, [ searchParams ]);


  const handleSearch = useCallback((event) => {
    setSearchField(event.target.value);
    setSearchTerm(event.target.value);
  }, []);

  const handlePreviewDictionary = (dictionaryId) => {
    setPreviewDictionaryId(dictionaryId);
    toggleDictionaryModal();
  };

  const handleViewDetails = (popupId) => {
    navigate(urlPagePopupsDetails({ popupId }));
  };

  const handleDelete = (popupId) => {
    deletePopup({ popupId })
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'ui-general-deleted-successfully' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'ui-general-delete-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  const handleClickImage = (item) => {
    setPreviewImage(item);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    setPreviewImage({});
    togglePreviewModal();
  };

  return (
    <>
      <Modal
        width={500}
        title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <img alt="icon" src={previewImage} />
      </Modal>

      <Modal
        title={<IntlMessages id="dictionaries-translation-preview" />}
        width="60%"
        centered
        destroyOnClose
        visible={openDictionaryModal}
        onCancel={toggleDictionaryModal}
        footer={null}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <PreviewDictionaryTranslation
          dictionaryId={previewDictionaryId}
          languages={languages?.languages}
        />
      </Modal>

      <Alert
        className="hp-mb-32"
        style={{ marginTop: '-20px' }}
        message=''
        description={
          <span>
            {intl.formatMessage({ id: 'features-title-notification' })}
            &nbsp;
            <Link
              style={{ color: appColors.blueLink }}
              to='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/847315004/Popups'
              target="_blank"
            >
              https://x-flow-ltd.atlassian.net/wiki/...
            </Link>
          </span>
        }
        type="info"
        showIcon
        icon={<RiInformationLine className="remix-icon" size={24} />}
      />

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='popups-breadcrumbs' />} />

        <Col md={16} span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="end"
            align="middle"
          >
            <Col sm={12} span={24}>
              <Input
                allowClear={!isEmpty(searchField)}
                placeholder={intl.formatMessage({ id: 'ui-general-search-by-title' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={searchField}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <PrivateComponent allowedPermissions={[ Permissions.OTHER.POPUPS.CREATE ]}>
                  <ActionButton
                    title={<IntlMessages id='ui-general-create' />}
                    icon={<RiAddLine />}
                    onClick={() => {
                      navigate(urlPagePopupCreate());
                    }}
                  />
                </PrivateComponent>
                <ActionButton
                  title=""
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Sidebar
          key={source}
          title={<Title level={5}>{intl.formatMessage({ id: 'popups-filter-title' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormPopupsFilter
            initialValues={initFilterValues}
            isSubmitting={isFetching}
            onCancel={resetFilter}
            onSubmit={applyFilter}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <div ref={elementRef}>
              <Table
                sticky
                scroll={{ x: 860 }}
                loading={isFetching || isDeletingPopup}
                rowKey="id"
                columns={
                  getPopupsTableColumns(
                    handlePreviewDictionary,
                    handleViewDetails,
                    handleDelete,
                    handleClickImage,
                    popupsConfig,
                    size,
                  )
                }
                dataSource={data.data}
                onChange={handleChangeTableParams}
                pagination={{
                  ...basePagination,
                  current: pagination.page,
                  pageSize: pagination.limit,
                  total: data.pagination?.total,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PagePopups;
