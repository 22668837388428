import React, { useEffect, useState } from 'react';
import { Card, Col, Pagination, Popconfirm, Row, Spin, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Filter } from 'iconsax-react';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import { MdKeyboardReturn } from 'react-icons/md';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import urlPageAchievements from '../../../../urls/urlPageAchievements';
import appColors from '../../../../const/colors';
import { imageStatuses } from '../../../../const/imageStatuses';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { useGetAchievementQuery, useUpdateAchievementMutation } from '../../api/achievementsApiSlice';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import FormCreateEditAchievement from '../../forms/FormCreateEditAchievement';
import ContentCard from '../../components/ContentCard';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import TotalLabel from '../../../../components/TotalLabel';
import ActionButton from '../../../../layout/components/action-button';
import useToggle from '../../../../app/hooks/useToggle';
import FormImagesFilter from '../../forms/FormImagesFilter';
import Sidebar from '../../../../components/sidebar/Sidebar';
import getQueryParams from '../../../../lib/getQueryParams';
import useResetFilter from '../../../../app/hooks/useResetFilter';
import { useAttachImageMutation, useGetImagesQuery } from '../../api/imagesApiSlice';



const PageAchievementsEdit = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [ isFilterOpen, toggleFilter ] = useToggle();
  const navigate = useNavigate();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ achievementInitialValues, setAchievementInitialValues ] = useState({});
  const [ achievementInitialErrors, setAchievementInitialErrors ] = useState({});
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const searchFields = [ 'id', 'folder', 'category', 'main_category', 'image_type', 'tags', 'taps_from', 'taps_to', 'colors_from', 'colors_to', 'imported_from', 'imported_to', 'released_from', 'released_to' ];
  const resetFilter = useResetFilter(searchQueryParams, setSearchParams, setInitFilterValues);

  const {
    pagination,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({
    searchFields,
    isFilter: true,
    paginationInit: {
      page: 1,
      limit: 12,
    } });

  const {
    data: { data: achievement } = { data: {} },
    isFetching: isAchievementFetching,
  } = useGetAchievementQuery(id);

  const { data: {
    data: images = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching } = useGetImagesQuery({
    queryParams: searchParams.toString().includes('search') ? `${searchParams.toString()};status:ready_for_release` : `${searchParams.toString()}&search=status:ready_for_release`,
  });

  const [ updateAchievement, { isLoading: isUpdatingAchievement } ] = useUpdateAchievementMutation();
  const [ attachImage, { isLoading: isAttaching } ] = useAttachImageMutation();

  useEffect(() => {
    if (!achievementInitialValues.name && achievement?.name) {
      setAchievementInitialValues({
        name: achievement?.name,
        code: achievement?.code,
        description: achievement?.description,
      });
    }
  }, [ achievement ]);

  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }
  }, [ searchParams ]);

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if ([ 'category', 'image_type', 'status', 'tags' ].includes(key) && value?.length) {
          if ([ 'category', 'tags' ].includes(key)) {
            initValues[key] = value.split(',').map((item) => Number(item));
          } else {
            initValues[key] = value.split(',');
          }
        } else if (value !== '') {
          initValues[key] = value;
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);

  const handleSubmit = (values) => {
    const action = updateAchievement({ id, payload: values });

    action
      .unwrap()
      .then(() => {
        setAchievementInitialValues({
          name: values.name,
          code: achievement?.code,
          description: values.description,
        });
      })
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'achievements-update-successfully' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">
              {intl.formatMessage({ id: 'achievements-update-failed' })}
            </p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );

        setAchievementInitialErrors(error?.data?.errors);
      });
  };

  const handleAttach = (imageId, status) => {
    const data = {
      attached_to: id,
      type: imageStatuses.achievement,
      status,
    };

    attachImage({ id: imageId, payload: data })
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'achievements-attach-success' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p>{intl.formatMessage({ id: 'achievements-attach-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  const actions = (item, isDetach = false) => {
    return [ (
      <Tooltip
        key="download"
        placement="bottom"
        title={<IntlMessages id={isDetach ? 'ui-general-detach-image' : 'ui-general-attach-image'} />}
      >
        {isDetach ? (
          <Popconfirm
            title={<IntlMessages id="achievements-detach-confirm-message" />}
            placement="top"
            onConfirm={() => {
              handleAttach(item.id, imageStatuses.ready_for_release);
            }}
            okText={<IntlMessages id="ui-general-yes" />}
            cancelText={<IntlMessages id="ui-general-no" />}
            icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
            okButtonProps={{ danger: true }}
          >
            <DeleteOutlined
              style={{ color: appColors.orange }}
            />
          </Popconfirm>
        ) : (
          <Popconfirm
            title={<IntlMessages id="achievements-attach-confirm-message" />}
            placement="top"
            onConfirm={() => {
              handleAttach(item.id, imageStatuses.achievement);
            }}
            okText={<IntlMessages id="ui-general-yes" />}
            cancelText={<IntlMessages id="ui-general-no" />}
            icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
          >
            <PlusOutlined
              style={{ color: appColors.mediumGreen }}
            />
          </Popconfirm>)}
      </Tooltip>
    ) ];
  };

  const applyFilter = (values) => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        queryParams[key] = key === 'page' ? 1 : value;
      });

    queryParams.search = Object.entries(values)
      .map(([ field, value ]) => {
        if (searchFields.includes(field)) {
          if ((isArray(value) && !value.length) || !value) {
            return '';
          }

          return isString(value) ? `${field}:${value.trim()}` : `${field}:${value}`;
        }
        return '';
      })
      .filter((item) => item !== '')
      .join(';');

    setSearchParams(queryParams);
    toggleFilter();
  };

  const showTotal = (total) => <TotalLabel total={total} />;

  const isLoading = isAchievementFetching || isUpdatingAchievement || isFetching || isAttaching;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageAchievements()}>
              <IntlMessages id='achievements-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={achievement?.name}
        />
        <Col>
          <ActionButton
            icon={<MdKeyboardReturn className="hp-mr-4" />}
            onClick={() => {
              navigate(urlPageAchievements());
            }}
            title={<IntlMessages id='ui-general-back' />}
          />
        </Col>
      </Row>

      <Spin spinning={isLoading} tip={intl.formatMessage({ id: 'ui-general-loading' })} >
        <Row gutter={[ 32, 32 ]} className="hp-mb-32">
          <Col span={24}>
            <Card className="hp-border-color-black-40 hp-card-6">
              <FormCreateEditAchievement
                isEdit
                initialValues={achievementInitialValues}
                onSubmit={handleSubmit}
                onCancel={() => {}}
                isSubmitting={isUpdatingAchievement}
                initialErrors={achievementInitialErrors}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={[ 32, 32 ]}>
          <Sidebar
            visible={isFilterOpen}
            toggleSidebar={toggleFilter}
            width={600}
          >
            <FormImagesFilter
              initialValues={initFilterValues}
              onSubmit={applyFilter}
              onCancel={resetFilter}
              isSubmitting={false}
              excludedFields={[ 'status', 'released_from', 'released_to' ]}
            />
          </Sidebar>

          <Col xs={24} lg={12}>
            <Card
              width="100%" title={(
                <div className="hp-d-flex hp-d-flex-between">
                  <IntlMessages id="achievements-images" />
                  <ActionButton
                    title=""
                    icon={<Filter size={18} />}
                    onClick={toggleFilter}
                  />
                </div>
              )}
            >
              {images?.length ? (
                <Row gutter={[ 16, 32 ]}>
                  {images?.map((item) => {
                    return (
                      <Col key={item.id} style={{ display: 'flex', justifyContent: 'center' }} xs={24} sm={12} xxl={8}>
                        <ContentCard
                          data={item}
                          onClick={() => {}}
                          actions={actions(item)}
                        />
                      </Col>
                    );
                  })}
                  <Col span={24}>
                    {dataPagination.total && <Pagination
                      total={dataPagination.total}
                      showTotal={showTotal}
                      pageSize={pagination.limit}
                      current={pagination.page}
                      pageSizeOptions={[ '12', '24', '48', '96' ]}
                      onChange={(page, size) => {
                        handleChangeTableParams({
                          current: page,
                          pageSize: size,
                        }, {}, {});
                      }}
                    />}
                  </Col>
                </Row>
              ) : (
                <Row>
                  <IntlMessages id="ui-general-no-images-found" />
                </Row>
              )}
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card width="100%" title={<IntlMessages id="achievements-title" />}>
              <Row gutter={[ 16, 32 ]}>
                {achievement?.images?.data?.map((item) => {
                  return (
                    <Col className="hp-d-flex hp-flex-wrap" key={item.id} sm={12} xxl={8}>
                      <ContentCard
                        data={item}
                        onClick={() => {}}
                        actions={actions(item, true)}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default PageAchievementsEdit;
