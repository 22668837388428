import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Space, Card, Table, Input } from 'antd';
import { Filter } from 'iconsax-react';
import isString from 'lodash/isString';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { Search } from 'react-iconly';
import { useIntl } from 'react-intl';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import ActionButton from '../../../../layout/components/action-button';
import useToggle from '../../../../app/hooks/useToggle';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import getQueryParams from '../../../../lib/getQueryParams';
import basePagination from '../../../../const/pagination';
import { getAchievementsTableColumns, SEARCH_FILTER_VALUES, SEARCH_VALUES } from './PageAchievements.const';
import TotalLabel from '../../../../components/TotalLabel';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import Sidebar from '../../../../components/sidebar/Sidebar';
import {
  useCreateAchievementMutation,
  useDeleteAchievementMutation,
  useGetAchievementsQuery,
} from '../../api/achievementsApiSlice';
import urlPageAchievementsEdit from '../../../../urls/urlPageAchievementsEdit';
import FormAchievementsFilter from '../../forms/FormAchievementsFilter';
import urlPageAchievementsDetails from '../../../../urls/urlPageAchievementsDetails';
import FormCreateEditAchievement from '../../forms/FormCreateEditAchievement';
import useResetFilter from '../../../../app/hooks/useResetFilter';



const PageAchievements = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [ searchField, setSearchField ] = useState('');
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ initFilterValues, setInitFilterValues ] = useState({});

  const [ achievementInitialErrors, setAchievementInitialErrors ] = useState({});

  const [ filterOpen, toggleFilter ] = useToggle();
  const [ isOpenCreateAchievementSidebar, toggleCreateAchievementSidebar ] = useToggle();
  const resetFilter = useResetFilter(searchQueryParams, setSearchParams, setInitFilterValues);

  const {
    pagination,
    searchParams,
    search,
    setSearchTerm,
    handleChangeTableParams,
  } = useQueryParams({
    searchFields: SEARCH_VALUES,
    isFilter: true,
  });

  const { data: {
    data: achievements = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching } = useGetAchievementsQuery({
    queryParams: `${searchParams.toString()}`,
  });

  const [ deleteAchievement, { isLoading: isDeletingAchievement } ] = useDeleteAchievementMutation();
  const [ createAchievement, { isLoading: isCreatingAchievement } ] = useCreateAchievementMutation();

  const applyFilter = (values) => {
    const queryParams = {};

    Array.from(searchQueryParams.entries())
      .forEach(([ key, value ]) => {
        queryParams[key] = key === 'page' ? 1 : value;
      });

    queryParams.search = Object.entries(values)
      .map(([ field, value ]) => {
        if (SEARCH_FILTER_VALUES.includes(field)) {
          if ((isArray(value) && !value.length) || !value) {
            return '';
          }

          return isString(value) ? `${field}:${value.trim()}` : `${field}:${value}`;
        }
        return '';
      })
      .filter((item) => item !== '')
      .join(';');

    setSearchField('');
    setSearchParams(queryParams);
    toggleFilter();
  };

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (value !== '') {
          initValues[key] = value;
        }

        if (SEARCH_VALUES.includes(key)) {
          setSearchField(value);
          setSearchTerm(value);
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }
  }, [ searchParams ]);

  const handleSearch = useCallback((event) => {
    setSearchField(event.target.value);
    setInitFilterValues({
      reset: true,
    });
    setSearchTerm(event.target.value);
  }, []);

  const handleView = (id) => {
    navigate(urlPageAchievementsDetails({ id }));
  };

  const handleEdit = (id) => {
    navigate(urlPageAchievementsEdit({ id }));
  };

  const handleCreate = (data, resetForm) => {
    createAchievement({ payload: data })
      .unwrap()
      .then((response) => {
        toggleCreateAchievementSidebar();
        resetForm();
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'achievements-create-successfully' }),
        );
        navigate(urlPageAchievementsEdit({ id: response?.data?.id }));
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">
              {intl.formatMessage({ id: 'achievements-create-failed' })}
            </p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );

        setAchievementInitialErrors(error?.data?.errors || {});
      });
  };

  const handleDelete = (id) => {
    deleteAchievement(id)
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'achievements-delete-successfully' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <>
            <p className="hp-mb-4">{intl.formatMessage({ id: 'achievements-delete-failed' })}</p>
            {error?.data?.message && <p>{ error.data.message }</p>}
          </>,
        );
      });
  };

  const isLoading = isFetching || isDeletingAchievement || isCreatingAchievement;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='achievements-breadcrumbs' />} />

        <Col>
          <Space>
            <TotalLabel total={dataPagination.total ?? 0} />
            <Col>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={searchField}
                onChange={handleSearch}
              />
            </Col>
            <ActionButton
              onClick={toggleCreateAchievementSidebar}
              title={<IntlMessages id='ui-general-create' />}
            />
            <ActionButton
              title=""
              icon={<Filter size={18} />}
              onClick={toggleFilter}
            />
          </Space>
        </Col>

        <Sidebar
          title={<IntlMessages id='achievements-filter-sidebar' />}
          visible={filterOpen}
          toggleSidebar={toggleFilter}
        >
          <FormAchievementsFilter
            initialValues={initFilterValues}
            onSubmit={applyFilter}
            onCancel={resetFilter}
            isSubmitting={false}
          />
        </Sidebar>

        <Sidebar
          title={<IntlMessages id='achievements-create-sidebar' />}
          visible={isOpenCreateAchievementSidebar}
          toggleSidebar={toggleCreateAchievementSidebar}
        >
          <FormCreateEditAchievement
            initialValues={{}}
            initialErrors={achievementInitialErrors}
            onSubmit={handleCreate}
            onCancel={() => {}}
            isSubmitting={isCreatingAchievement}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={isLoading}
              rowKey="id"
              columns={getAchievementsTableColumns(handleView, handleEdit, handleDelete)}
              dataSource={achievements}
              onChange={handleChangeTableParams}
              pagination={{
                current: pagination.page,
                pageSize: pagination.limit,
                total: dataPagination?.total,
                ...basePagination,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageAchievements;
