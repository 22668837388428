const achievements = {
  'achievements-breadcrumbs': 'Достижения',
  'achievements-title': 'Достижения',
  'achievements-images': 'Готово к публикации',
  'achievements-code-column': 'Код',
  'achievements-content-column': 'Контент',
  'achievements-description-column': 'Описание',
  'achievements-created-at-column': 'Дата создания',
  'achievements-filter-id-label': 'ID',
  'achievements-filter-code-label': 'Код',
  'achievements-filter-name-label': 'Название',
  'achievements-filter-created-from-label': 'Создан с',
  'achievements-filter-created-to-label': 'Создан по',
  'achievements-form-name-label': 'Название',
  'achievements-form-name-placeholder': 'Введите название',
  'achievements-form-code-label': 'Код',
  'achievements-form-code-placeholder': 'Введите название',
  'achievements-form-description-label': 'Описание',
  'achievements-form-description-placeholder': 'Добавьте описание',
  'achievements-create-successfully': 'Достижение создано',
  'achievements-create-failed': 'Ошибка при создании достижения',
  'achievements-update-successfully': 'Достижение обновлено',
  'achievements-update-failed': 'Ошибка при обновлении достижения',
  'achievements-attach-success': 'Картинка прикреплена к достижению',
  'achievements-attach-failed': 'Ошибка при прикреплении картинки к достижению',
  'achievements-delete-successfully': 'Достижение удалено',
  'achievements-delete-failed': 'Ошибка при удалении достижения',
  'achievements-delete-btn-confirm-message': 'Вы уверены, что хотите удалить это достижение?',
  'achievements-attach-confirm-message': 'Вы уверены, что хотите прикрепить эту картинку к достижению?',
  'achievements-detach-confirm-message': 'Вы уверены, что хотите открепить эту картинку от достижения?',
  'achievements-create-sidebar': 'Создать достижение',
  'achievements-filter-sidebar': 'Фильтр достижений',
  'achievements-attached-images': 'Прикрепленные картинки',
  'achievements-no-attached-images': 'Картинки еще не прикреплены к достижению',
};

export default achievements;
