const achievements = {
  'achievements-breadcrumbs': 'Achievements',
  'achievements-title': 'Achievements',
  'achievements-images': 'Ready to release',
  'achievements-code-column': 'Code',
  'achievements-content-column': 'Content',
  'achievements-description-column': 'Description',
  'achievements-created-at-column': 'Created At',
  'achievements-filter-id-label': 'ID',
  'achievements-filter-code-label': 'Code',
  'achievements-filter-name-label': 'Name',
  'achievements-filter-created-from-label': 'Created From',
  'achievements-filter-created-to-label': 'Created To',
  'achievements-form-name-label': 'Name',
  'achievements-form-name-placeholder': 'Enter name',
  'achievements-form-code-label': 'Code',
  'achievements-form-code-placeholder': 'Enter code',
  'achievements-form-description-label': 'Description',
  'achievements-form-description-placeholder': 'Enter description',
  'achievements-create-successfully': 'Achievement created',
  'achievements-create-failed': 'Create achievement failed',
  'achievements-update-successfully': 'Achievement updated',
  'achievements-update-failed': 'Update achievement failed',
  'achievements-attach-success': 'Image attached to achievement',
  'achievements-attach-failed': 'Attach image to achievement failed',
  'achievements-delete-successfully': 'Achievement deleted',
  'achievements-delete-failed': 'Delete achievement failed',
  'achievements-delete-btn-confirm-message': 'Are you sure you want to delete this achievement?',
  'achievements-attach-confirm-message': 'Are you sure you want to attach this image to achievement?',
  'achievements-detach-confirm-message': 'Are you sure you want to detach this image from achievement?',
  'achievements-create-sidebar': 'Create achievement',
  'achievements-filter-sidebar': 'Filter achievements',
  'achievements-attached-images': 'Attached images',
  'achievements-no-attached-images': 'Images does not attached to achievement',
};

export default achievements;
