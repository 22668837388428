import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import range from 'lodash/range';
import dayjs from 'dayjs';
import { Empty, Row, Skeleton, Tag } from 'antd';
import { DATE_TIME_FORMAT, EMPTY_IMAGE_PLACEHOLDER } from '../../../../const/system';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



const DynamicNewsPriority = ({ news, isLoading, onOrderChange }) => {
  const intl = useIntl();
  const [ initOrder, setInitOrder ] = useState([]);
  const [ items, setItems ] = useState(initOrder);

  useEffect(() => {
    if (news.length !== 0) {
      const init = range(1, news.length + 1).map((item) => `${item}`);

      setInitOrder(init);
      setItems(init);
    }
  }, [ news ]);

  const handleDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const initialOrder = [ ...items ];
    const [ reorderedItem ] = initialOrder.splice(result.source.index, 1);

    initialOrder.splice(result.destination.index, 0, reorderedItem);
    setItems(initialOrder);
    onOrderChange(initialOrder);
  };

  const willPublished = (news) => {
    return (
      <div>
        {news?.is_published ? (
          <>
            <hr />
            <span style={{ fontSize: '12px', color: 'black', fontWeight: 500 }}>
              {`${intl.formatMessage({ id: 'ui-general-published-at' })}: ${dayjs(news?.published_at).format(DATE_TIME_FORMAT)}`}
            </span>
          </>
        ) : (
          <Tag className='hp-mt-4'>
            <IntlMessages id="ui-general-not-published" />
          </Tag>
        )}
      </div>
    );
  };

  return (
    <div>
      {isLoading ? <Skeleton /> : (
        <div>
          {!news.length ? (
            <Row className='centered-item'>
              <Empty
                description={
                  <div>
                    <IntlMessages id="news-priority-empty" />
                  </div>
                }
              />
            </Row>
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="items">
                {({ innerRef, droppableProps, placeholder }) => {
                  return (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                    <div className="news-priority-container" ref={innerRef} {...droppableProps}>
                      {items.map((item, index) => {
                        return (
                          <Draggable draggableId={item} index={index} key={item}>
                            {({ innerRef, draggableProps, dragHandleProps }) => {
                              const position = Number(item) - 1;

                              return (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                                <div className={`hp-d-flex hp-d-flex-between news-priority-item ${!news[position]?.is_published ? 'news-priority-item-off' : ''}`} ref={innerRef} {...draggableProps} {...dragHandleProps}>
                                  <div className="hp-d-flex hp-align-items-center">
                                    <LazyLoadImage
                                      className='news-priority-item__image'
                                      effect='blur'
                                      alt="picture preview"
                                      src={news[position]?.preview ?? EMPTY_IMAGE_PLACEHOLDER}
                                    />
                                    <div className="hp-p-16">
                                      {news[position]?.title}
                                        &nbsp;
                                      {`(ID: ${news[position]?.id})`}
                                      <div className="hp-pt-4">
                                        {willPublished(news[position])}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      )}
    </div>
  );
};

DynamicNewsPriority.propTypes = {
  news: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onOrderChange: PropTypes.func.isRequired,
};

export default DynamicNewsPriority;
