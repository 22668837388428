import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '../../layout/components/lang/IntlMessages';



const TotalLabel = ({ total = 0 }) => {
  return (
    <span className="hp-badge-text hp-font-weight-600 hp-mb-0 hp-text-color-dark-30">
      <IntlMessages id='ui-general-total' />
      {': '}
      <span>{ total }</span>
    </span>
  );
};

TotalLabel.propTypes = {
  total: PropTypes.number,
};

export default TotalLabel;
