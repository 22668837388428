import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';



const fileProp = 'originFileObj';
const mkTemplateDynamicFormData = (values, skippedKeys = []) => {
  const clonedValues = cloneDeep(values);
  const formData = new FormData();

  const isNotSkipped = (key) => !skippedKeys.includes(key);

  Object.entries(clonedValues)
    .forEach(([ key, value ]) => {
      if (isNotSkipped(key)) {
        if (key === 'data') {
          Object.entries(value)
            .forEach(([ innerKey, innerValue ]) => {
              if (innerValue.hasOwnProperty('template_field_id') && innerValue.hasOwnProperty('value')) {
                formData.append(`${key}[${innerKey}][template_field_id]`, innerValue.template_field_id);
                if (Array.isArray(innerValue.value) && innerValue.value[0]?.hasOwnProperty(fileProp)) {
                  formData.append(`${key}[${innerKey}][value]`, innerValue.value[0][fileProp]);
                } else if (Array.isArray(innerValue.value) && !innerValue.value[0]?.hasOwnProperty(fileProp) && innerValue.value[0]?.hasOwnProperty('url')) {
                  formData.append(`${key}[${innerKey}][value]`, null);
                } else if (Array.isArray(innerValue.value)) {
                  if (!innerValue.value.length) {
                    formData.append(`${key}[${innerKey}][value]`, null);
                  } else {
                    innerValue.value?.forEach(((item) => formData.append(`${key}[${innerKey}][value][]`, item)));
                  }
                } else {
                  formData.append(`${key}[${innerKey}][value]`, innerValue.value);
                }
              }
            });
        } else if (key === 'target') {
          Object.entries(value)
            .forEach(([ innerKey, innerValue ]) => {
              if (isArray(innerValue)) {
                innerValue.forEach((item) => formData.append(`${key}[${innerKey}][]`, item));
              } else {
                formData.append(`${key}[${innerKey}]`, innerValue);
              }
            });
        } else if (Array.isArray(value)) {
          if (value[0]?.hasOwnProperty(fileProp)) {
            formData.append(key, value[0][fileProp]);
          } else if (!value[0]?.hasOwnProperty(fileProp) && value[0]?.hasOwnProperty('url')) {
            formData.append(key, null);
          } else {
            value.forEach((item) => formData.append(`${key}[]`, item));
          }
        } else {
          if (value === '') {
            formData.append(key, null);
          } else {
            formData.append(key, value);
          }
        }
      }
    });

  return formData;
};

export default mkTemplateDynamicFormData;
