import layout_ru_RU from '../../layout/translations/layout_ru_RU';
import sidebar_ru_RU from '../../layout/components/menu/translations/sidebar_ru_RU';
import users_ru_RU from '../../domains/RBAC/translations/Users/users_ru_RU';
import roles_ru_RU from '../../domains/RBAC/translations/Roles/roles_ru_RU';
import permissions_ru_RU from '../../domains/RBAC/translations/Permissions/permissions_ru_RU';
import profile_ru_RU from '../../domains/Profile/translations/Profile/profile_ru_RU';
import languages_ru_RU from '../../domains/Language/translations/languages_ru_RU';
import structures_ru_RU from '../../domains/Structures/translations/structures_ru_RU';
import dictionaries_ru_RU from '../../domains/Dictionary/translations/dictionaries_ru_RU';
import translations_ru_RU from '../../domains/Translation/translations/translations_ru_RU';
import pushIcons_ru_RU from '../../domains/Other/translations/pushIcons_ru_RU';
import content_ru_RU from '../../domains/Content/translations/content_ru_RU';
import deeplinks_ru_RU from '../../domains/Other/translations/deeplinks_ru_RU';
import news_ru_RU from '../../domains/Content/translations/news_ru_RU';
import newsPriority_ru_RU from '../../domains/Content/translations/newsPriority_ru_RU';
import templates_ru_RU from '../../domains/Constructors/translations/templates_ru_RU';
import dynamicNews_ru_RU from '../../domains/Content/translations/dynamicNews_ru_RU';
import categories_ru_RU from '../../domains/Content/translations/categories_ru_RU';
import achievements_ru_RU from '../../domains/Content/translations/achievements_ru_RU';
import features_ru_RU from '../../domains/Featured/translations/features_ru_RU';
import featuresPriority_ru_RU from '../../domains/Featured/translations/featuresPriority_ru_RU';
import presets_ru_RU from '../../domains/Presets/translations/presets_ru_RU';
import popups_ru_RU from '../../domains/Other/translations/popups_ru_RU';



const ru_RU = {
  'ui-general-search': 'Поиск...',
  'ui-general-search-by-title': 'Поиск по названию...',
  'ui-general-action': 'Действие',
  'ui-general-show': 'Показать',
  'ui-general-back': 'Назад',
  'ui-general-back-to-list': 'Назад к списку',
  'ui-general-template-data': 'Данные шаблона',
  'ui-general-additional-parameters': 'Дополнительные параметры',
  'ui-general-optional-data': 'Опциональные данные',
  'ui-general-edit': 'Редактировать',
  'ui-general-save': 'Сохранить',
  'ui-general-update': 'Обновить',
  'ui-general-view': 'Посмотреть',
  'ui-general-create': 'Создать',
  'ui-general-delete': 'Удалить',
  'ui-general-id': 'ID',
  'ui-general-ids-placeholder': 'Введите Id',
  'ui-general-id-placeholder': 'Введите Id',
  'ui-general-min': 'Мин.',
  'ui-general-max': 'Макс.',
  'ui-general-min-version': 'Мин. версия',
  'ui-general-max-version': 'Макс. версия',
  'ui-general-platform': 'Платформа',
  'ui-general-countries': 'Страны',
  'ui-general-preview': 'Предпросмотр',
  'ui-general-content': 'Контент',
  'ui-general-title': 'Название',
  'ui-general-status': 'Статус',
  'ui-general-priority': 'Приоритет',
  'ui-general-main-info': 'Основная информация',
  'ui-general-template': 'Шаблон',
  'ui-general-specify-title': 'Введите название',
  'ui-general-choose-template': 'Выберите шаблон',
  'ui-general-preset': 'Пресет',
  'ui-general-choose-preset': 'Выберите пресет',
  'ui-general-specify-value': 'Введите значение',
  'ui-general-cancel': 'Отмена',
  'ui-general-name': 'Имя',
  'ui-general-not-published': 'Не опубликовано',
  'ui-general-display-name': 'Отображаемое имя',
  'ui-general-description': 'Описание',
  'ui-general-submit': 'Подтвердить',
  'ui-general-apply': 'Применить',
  'ui-general-copied': 'Скопировано',
  'ui-general-copy': 'Копировать',
  'ui-general-reset': 'Сбросить',
  'ui-general-download': 'Скачать',
  'ui-general-reopen': 'Переоткрыть',
  'ui-general-clone': 'Клонировать',
  'ui-general-import': 'Импортировать',
  'ui-general-ok': 'Ок',
  'ui-general-created-successfully': 'Данные успешно созданы',
  'ui-general-created-failed': 'Ошибка при создании данных',
  'ui-general-updated-successfully': 'Данные успешно обновлены',
  'ui-general-updated-failed': 'Ошибка при редактировании данных',
  'ui-general-deleted-successfully': 'Данные успешно удалены',
  'ui-general-delete-failed': 'Ошибка при удалении данных',
  'ui-general-logout': 'Выход',
  'ui-general-yes': 'Да',
  'ui-general-no': 'Нет',
  'ui-general-required': 'Обязательно',
  'ui-general-validation-required': 'Заполните поле',
  'ui-general-label': 'Название',
  'ui-general-created-at': 'Дата создания',
  'ui-general-updated-at': 'Дата изменения',
  'ui-general-published-at': 'Опубликовано',
  'ui-general-roles': 'Роли',
  'ui-general-filter': 'Фильтр',
  'ui-general-permissions': 'Права доступа',
  'ui-general-home': 'Домой',
  'ui-general-languages': 'Языки',
  'ui-general-structures': 'Структуры',
  'ui-general-dictionaries': 'Словари',
  'ui-general-list': 'Список',
  'ui-general-delete-confirm-message': 'Вы уверены, что хотите удалить элемент?',
  'ui-general-back-button': 'Назад',
  'ui-general-filter-title': 'Фильтр',
  'ui-general-total': 'Всего',
  'ui-general-loading': 'Загрузка. Ожидайте...',
  'ui-general-success': 'Успех',
  'ui-general-error': 'Ошибка',
  'ui-general-all': 'Все',
  'ui-general-on': 'Вкл',
  'ui-general-off': 'Выкл',
  'ui-general-restricted': 'У вас нет прав чтобы произвести данное действие',
  'ui-general-attach-image': 'Прикрепить картинку',
  'ui-general-attach-image-click': 'Нажмите на картинку, чтобы прикрепить',
  'ui-general-detach-image': 'Открепить картинку',
  'ui-general-no-images-found': 'Картинки не найдены',
  'ui-general-published-from': 'Опубликован с',
  'ui-general-published-to': 'Опубликован по',
  'ui-general-released-at': 'Релиз с',
  'ui-general-released-to': 'Релиз по',
  'ui-general-published': 'Опубликовано',
  'ui-general-invalid-date': 'Некорректная дата',
  'ui-general-documentation-link': 'Ссылка на документацию',
  'ui-general-without-category': 'Без категории',
  'ui-general-select-all': 'Выбрать все',
  'ui-general-table-created-at-column': 'Дата создания',
  'ui-general-table-updated-at-column': 'Дата обновления',
  'ui-general-filter-id-key-help': 'Можно вводить значения ID через запятую',
  'ui-general-upload-image': 'Загрузить изображение',
  'ui-general-was-not-published': 'Данные ни разу не публиковались',
  'ui-general-last-published-at': 'Дата последней публикации',
  'ui-general-unpublish': 'Снять с публикации',
  'ui-general-unpublish-successfully': 'Успешно снято с публикации',
  'ui-general-unpublish-failed': 'Ошибка при снятии с публикации',
  'ui-general-unpublished-tooltip-title': 'Имеются неопубликованные изменения',
  'ui-general-image-preview': 'Просмотр картинки',
  ...layout_ru_RU,
  ...news_ru_RU,
  ...sidebar_ru_RU,
  ...features_ru_RU,
  ...users_ru_RU,
  ...roles_ru_RU,
  ...permissions_ru_RU,
  ...profile_ru_RU,
  ...languages_ru_RU,
  ...structures_ru_RU,
  ...dictionaries_ru_RU,
  ...translations_ru_RU,
  ...pushIcons_ru_RU,
  ...deeplinks_ru_RU,
  ...content_ru_RU,
  ...newsPriority_ru_RU,
  ...templates_ru_RU,
  ...dynamicNews_ru_RU,
  ...categories_ru_RU,
  ...achievements_ru_RU,
  ...featuresPriority_ru_RU,
  ...presets_ru_RU,
  ...popups_ru_RU,
};

export default ru_RU;
