import React from 'react';
import { Form, FormItem, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Col, Row, Upload, Typography, Spin } from 'antd';
import { RiUploadCloud2Line } from 'react-icons/ri';
import { useIntl } from 'react-intl';
import Yup from '../../../../vendor/yup';
import { FILE_SIZE, SUPPORTED_FORMATS, SUPPORTED_TYPES } from './FormUploadPushIcon.const';
import { LOADING_TIP_PLACEHOLDER } from '../../../../const/system';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import HtmlEditor from '../../../../components/HtmlEditor/HtmlEditor';
import makePreventOnKeyDown from '../../../../lib/makePreventOnKeyDown';



const { Text } = Typography;

const validationSchema = Yup.object().shape({
  list: Yup
    .mixed()
    .test(
      'requiredFile',
      'File is required',
      (value) => {
        return value?.length;
      },
    )
    .test(
      'fileSize',
      'File size is too large and should be less than 500Kb',
      (value) => {
        if (value.length) {
          return value && value[0]?.size <= FILE_SIZE;
        }

        return true;
      },
    ),
  title: Yup.string().nullable(),
  text: Yup.string().nullable(),
});


const FormUploadPushIcon = ({
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={{
        file: null,
        list: [],
        title: null,
        text: null,
        folder: new Date().getTime(),
      }}
      onSubmit={async (values, { resetForm, setErrors }) => {
        await onSubmit(values);
        resetForm();
        setErrors({});
      }}
    >
      {({ values, errors, isValid, dirty, setFieldValue, setErrors, resetForm }) => {
        return (
          <Form layout="vertical" style={{ width: '100%' }} onKeyDown={makePreventOnKeyDown}>
            <Spin spinning={isSubmitting} tip={LOADING_TIP_PLACEHOLDER}>
              <FormItem
                label={intl.formatMessage({ id: 'push-icon-create-form-dropzone-label' })}
                name='file'
                valuePropName="file"
                required
              >
                <Upload.Dragger
                  name="file"
                  accept={SUPPORTED_FORMATS}
                  type={SUPPORTED_TYPES}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                  disabled={isSubmitting}
                  fileList={values.list}
                  onChange={(event) => {
                    setFieldValue('list', event.fileList);
                  }}
                  beforeUpload={(file) => {
                    setFieldValue('file', file);
                    return false;
                  }}
                  onRemove={async () => {
                    await setFieldValue('list', []);
                    await setFieldValue('file', null);
                    setErrors({});
                  }}
                  onPreview={() => {}}
                >
                  <p className="ant-upload-drag-icon">
                    <RiUploadCloud2Line className="remix-icon" />
                  </p>

                  <p className="ant-upload-text">
                    <IntlMessages id="push-icon-create-form-dropzone-description" />
                  </p>
                </Upload.Dragger>
                <div className="hp-mt-4">
                  <Text type="danger">{errors.list ? errors.list : null}</Text>
                </div>
              </FormItem>

              <FormItem
                label={intl.formatMessage({ id: 'push-icon-create-form-title-label' })}
                name='title'
                style={{ minHeight: '130px' }}
              >
                <HtmlEditor
                  onChange={(value) => {
                    setFieldValue('title', value);
                  }}
                  initialValue={values?.title ?? ''}
                  init={{
                    height: '125px',
                    plugins: 'preview autosave save directionality charmap pagebreak nonbreaking  wordcount emoticons',
                    toolbar: 'undo redo | bold italic fontsizeselect charmap emoticons',
                    toolbar_mode: 'sliding',
                    menubar: false,
                  }}
                />
              </FormItem>

              <FormItem
                label={intl.formatMessage({ id: 'push-icon-create-form-text-label' })}
                name='text'
                style={{ minHeight: '130px' }}
              >
                <HtmlEditor
                  onChange={(value) => {
                    setFieldValue('text', value);
                  }}
                  initialValue={values?.text ?? ''}
                  init={{
                    height: '125px',
                    plugins: 'preview autosave save directionality charmap pagebreak nonbreaking  wordcount emoticons',
                    toolbar: 'undo redo | bold italic fontsizeselect charmap emoticons',
                    toolbar_mode: 'sliding',
                    menubar: false,
                  }}
                />
              </FormItem>

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <Button
                    type='secondary'
                    onClick={async () => {
                      onCancel();
                      await setFieldValue('file', null);
                      await setFieldValue('list', []);
                      resetForm();
                      setErrors({});
                    }}
                  >
                    <IntlMessages id="ui-general-cancel" />
                  </Button>
                </Col>

                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-submit" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spin>
          </Form>
        );
      }}
    </Formik>
  );
};

FormUploadPushIcon.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormUploadPushIcon;
