import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';



const ActionButton = ({ title = 'button', onClick, icon = null, className = 'hp-mr-sm-8 hp-mr-16' }) => {
  return (
    <Button
      type="primary"
      className={className}
      ghost="true"
      onClick={onClick}
      icon={icon}
    >
      {title}
    </Button>
  );
};

ActionButton.propTypes = {
  title: PropTypes.any,
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ActionButton;
