const sidebar = {
  'sidebar-pages': 'Страницы',
  'sidebar-other': 'Другое',
  'sidebar-rbac': 'Управление доступом',
  'sidebar-pages-rbac-users': 'Пользователи',
  'sidebar-featured': 'Рекомендуемые',
  'sidebar-featured-news': 'Новости',
  'sidebar-featured-news-list': 'Список',
  'sidebar-featured-news-priority': 'Приоритет',
  'sidebar-featured-old-news': 'Старые новости',
  'sidebar-featured-old-news-list': 'Список',
  'sidebar-featured-old-news-priority': 'Приоритет',
  'sidebar-featured-news-icons': 'Иконки',
  'sidebar-featured-news-dictionary': 'Переводы',
  'sidebar-featured-news-dictionary-btn': 'Переводы кнопок',
  'sidebar-featured-daily': 'Ежедневные',
  'sidebar-featured-editors-choice': 'Выбор редакторов',
  'sidebar-featured-external-promo': 'Внешние промо',
  'sidebar-featured-priority': 'Приоритет',
  'sidebar-pages-push-icons': 'Пуш иконки',
  'sidebar-pages-deeplinks': 'Глубинные ссылки',
  'sidebar-pages-popups': 'Диалоги',
  'sidebar-pages-blank-page': 'Пустая страница',
  'sidebar-pages-error': 'Ошибки',
  'sidebar-view-profile': 'Профиль',
  'sidebar-translations': 'Переводы',
  'sidebar-content': 'Контент',
  'sidebar-content-preview': 'Картинки',
  'sidebar-content-categories': 'Категории',
  'sidebar-content-categories-priority': 'Приоритеты',
  'sidebar-content-achievements': 'Достижения',
  'sidebar-template-constructors': 'Конструктор шаблонов',
  'sidebar-templates': 'Шаблоны',
  'sidebar-presets': 'Пресеты',
  'sidebar-general-list': 'Список',
  'sidebar-general-priority': 'Приоритет',
};

export default sidebar;
