import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Space } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isObject from 'lodash/isObject';
import { MdOutlineUnpublished } from 'react-icons/md';
import { RiArrowLeftLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import urlPagePopups from '../../../../urls/urlPagePopups';
import urlPagePopupsDetails from '../../../../urls/urlPagePopupsDetails';
import { TYPE_IMAGE, TYPE_OBJECT } from '../../../Constructors/const/templates';
import { fieldTypeToValidationRule } from '../../../Content/forms/FormCreateDynamicNews/FormCreateDynamicNews.const';
import { POPUPS_SOURCE } from '../PagePopups/PagePopups.const';
import getFullFieldName from '../../../Content/utils/getFullFieldName';
import isFieldRequired from '../../../Content/utils/isFieldRequired';
import createFileFromURL from '../../../../lib/createFileFromURL';
import { mkValidationSchema } from '../../forms/FormEditPopup/FormEditPopup.const';
import getConfigurations from '../../../../lib/getConfigurations';
import transformErrors from '../../../../lib/transformErrors';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../layout/components/action-button';
import FormEditPopup from '../../forms/FormEditPopup';
import useModifiedDynamicDataErrors from '../../../../app/hooks/useModifiedDynamicDataErrors';
import { useGetSpecificPopupQuery, useUnpublishPopupMutation, useUpdatePopupMutation } from '../../api/popupsApiSlice';



let validation = {};

const PagePopupEdit = () => {
  const intl = useIntl();
  const { popupId } = useParams();
  const navigate = useNavigate();
  const language = useSelector(({ customise: { language } }) => language);
  const [ initialValues, setInitialValues ] = useState({ preset_id: null, data: {}, target: {} });
  const [ errors, setFormErrors ] = useState({});

  const [ updatePopup, { isLoading: isUpdatingPopup } ] = useUpdatePopupMutation();
  const { data: popupDetails = {}, isFetching: isPopupFetching } = useGetSpecificPopupQuery({ id: popupId }, { skip: !popupId });
  const [ unpublishPopup, { isLoading: isUnpublishPopupLoading } ] = useUnpublishPopupMutation();

  const popupsConfig = getConfigurations(POPUPS_SOURCE);
  const releaseDate = popupDetails?.released_at;
  const allowUnpublishPast = popupsConfig?.publish?.allow_unpublish_past ?? true;
  const allowUpdateInThePast = popupsConfig?.publish?.allow_update_past ?? true;
  const allowUnpublish = allowUnpublishPast ?
    Boolean(popupDetails?.is_published) :
    Boolean(popupDetails?.is_published) && dayjs().isSameOrBefore(dayjs(releaseDate), 'day');

  const modifiedErrors = useModifiedDynamicDataErrors(
    popupDetails?.template?.data.id,
    popupDetails?.template?.data,
    errors,
    language,
  );

  const getDynamicData = (popupData) => {
    const data = {};
    const dataSchema = {};

    popupData?.template?.data?.fields?.data.filter((field) => field.type !== TYPE_OBJECT)
      .forEach(async (field) => {
        const featureField = popupData?.dynamic_data?.data?.find((item) => item.template_field_id === field.id);
        const fieldName = getFullFieldName(field);

        if (isFieldRequired(field?.validations?.data || [])) {
          dataSchema[fieldName] = Yup.object().shape({
            template_field_id: Yup.number().required(),
            ...fieldTypeToValidationRule[field.type],
          });
        }

        if (field.type === TYPE_IMAGE) {
          if (featureField?.value) {
            const fileName = featureField.value?.split('/').pop();
            const file = await createFileFromURL(featureField.value, fileName);

            data[fieldName] = {
              value: [ {
                uid: '-1',
                name: featureField?.value,
                thumbUrl: featureField?.value,
                status: 'done',
                originFileObj: file,
                url: featureField?.value,
              } ],
              template_field_id: field.id,
            };
          } else {
            data[fieldName] = {
              value: [],
              template_field_id: field.id,
            };
          }
        } else {
          data[fieldName] = {
            value: featureField?.value,
            template_field_id: field.id,
          };
        }

        setInitialValues((prevState) => ({
          ...prevState,
          data,
        }));
      });

    validation = mkValidationSchema(
      popupsConfig,
      {
        data: Yup.object().shape(dataSchema),
      },
      popupDetails?.released_at,
      popupDetails?.released_to,
      allowUpdateInThePast,
    );

    return data;
  };

  useEffect(() => {
    setInitialValues({
      released_at: popupDetails?.released_at,
      released_to: popupDetails?.released_to,
      use_utc_zero_time: popupDetails?.use_utc_zero_time,
      template_id: popupDetails?.template?.data?.id,
      preset_id: null,
      title: popupDetails?.title,
      is_published: false,
      updated_at: popupDetails?.updated_at,
      published_at: popupDetails?.published_at,
      target: {
        min_version: popupDetails?.target?.data?.min_version ?? '',
        max_version: popupDetails?.target?.data?.max_version ?? '',
        platform: popupDetails?.target?.data?.platform,
        countries: [ ...popupDetails?.target?.data?.countries ?? [] ].sort(),
      },
      data: {},
    });

    getDynamicData(popupDetails);
  }, [ isPopupFetching ]);

  const handleUpdatePopup = (data) => {
    updatePopup({ popupId, values: data })
      .unwrap()
      .then((response) => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'ui-general-updated-successfully' }),
        );

        navigate(urlPagePopupsDetails({ popupId: response?.data?.id }));
      })
      .catch((error) => {
        if (isObject(error?.data?.errors)) {
          const formikInitialErrors = transformErrors(error?.data?.errors);

          setFormErrors(formikInitialErrors);
        }
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <p className="hp-mb-4">
            {intl.formatMessage({ id: 'ui-general-updated-failed' })}
            <div>
              {error?.data?.message && <p>{ error.data.message }</p>}
            </div>
          </p>,
        );
      });
  };

  const handleUnpublish = () => {
    unpublishPopup({ popupId })
      .unwrap()
      .then(() => {
        makeNotification(
          notificationTypes.success,
          intl.formatMessage({ id: 'ui-general-success' }),
          intl.formatMessage({ id: 'ui-general-unpublish-successfully' }),
        );
      })
      .catch((error) => {
        makeNotification(
          notificationTypes.error,
          intl.formatMessage({ id: 'ui-general-error' }),
          <div className="hp-mb-4">
            {intl.formatMessage({ id: 'ui-general-unpublish-failed' })}
            {error?.data?.message}
          </div>,
        );
      });
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPagePopups()}>
              <IntlMessages id='popups-breadcrumbs' />
            </Link>
          }
          breadCrumbParent2={(
            <Link to={urlPagePopupsDetails({ popupId })}>
              {popupId}
            </Link>
          )}
          breadCrumbActive={<IntlMessages id='ui-general-edit' />}
        />

        <Col md={12} span={24}>
          <Row
            gutter={[ 8, 8 ]}
            justify="end"
            align="middle"
          >
            {allowUnpublish && (
              <Col>
                <Space>
                  <ActionButton
                    title={intl.formatMessage({ id: 'ui-general-unpublish' })}
                    icon={<MdOutlineUnpublished className="hp-mr-8" size={18} />}
                    onClick={handleUnpublish}
                  />
                </Space>
              </Col>
            )}
            <Col>
              <Space>
                <Link to={urlPagePopups()}>
                  <Button
                    type="primary"
                    className="hp-mr-sm-8"
                    ghost="true"
                    icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
                  >
                    <IntlMessages id='ui-general-back-to-list' />
                  </Button>
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <FormEditPopup
              isSubmitting={isUpdatingPopup}
              isLoading={isPopupFetching || isUnpublishPopupLoading}
              initialErrors={modifiedErrors}
              initialValues={initialValues}
              popupsConfig={popupsConfig}
              validationSchema={validation}
              templateOptions={[ {
                label: popupDetails?.template?.data?.title,
                value: popupDetails?.template?.data?.id,
              } ]}
              templateFields={popupDetails?.template?.data?.fields?.data || []}
              isEnabledTemplateStatus={Boolean(popupDetails?.template?.data?.is_active)}
              onSubmit={handleUpdatePopup}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PagePopupEdit;
