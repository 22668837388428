import {Tag, Tooltip} from "antd";
import IntlMessages from "../../layout/components/lang/IntlMessages";
import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {DATE_TIME_FORMAT} from "../../const/system";



const PublishedTag = ({published, publishedDate}) => {
	const tooltipTitle = () => {
		if (published && publishedDate) {
			return (
				<>
					<IntlMessages id="ui-general-published-at" />
					{' '}
					{dayjs(publishedDate).format(DATE_TIME_FORMAT)}
				</>
			);
		}
		
		if (!published && publishedDate) {
			return (
				<>
					<IntlMessages id="ui-general-last-published-at" />
					{' '}
					{dayjs(publishedDate).format(DATE_TIME_FORMAT)}
				</>
			);
		}
		
		return <IntlMessages id="ui-general-was-not-published" />;
	}
	
	return (
		<Tooltip
			placement="top"
			title={tooltipTitle()}
		>
			<div>
				<Tag color={published ? 'success' : 'error'}>
					<IntlMessages id={published ? 'ui-general-yes' : 'ui-general-no'} />
				</Tag>
			</div>
		</Tooltip>
		
	);
};


const nullOrString = (props, propName, componentName) => {
	const value = props[propName];
	
	if (value !== null && typeof value !== 'string') {
		return new Error(
			`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected \`null\` or \`string\`.`
		);
	}
};

PublishedTag.propTypes = {
	published: PropTypes.bool.isRequired,
	publishedDate: nullOrString,
}

export default PublishedTag;